@import 'assets/styles/scss/variables';

// Select Field
.form-group-select,
.form-group-select_error,
.form-group-select--is-multi,
.form-group-select--is-multi_error {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(18, 42, 66, 0.05);
    [class*='-control'] {
        margin-top: 0 !important;
        padding: 0 !important;
        background-color: $white;
        border: 1px solid $gray-light !important;
        box-shadow: none !important;

        // &:hover {
        // 	border: 1px solid $gray-light !important;
        // 	box-shadow: none !important;
        // 	border-color: $gray-light !important;
        // }
    }

    [class*='-option'] {
        background-color: $white !important ;
        color: unset !important;
    }
    [class*='-option']:hover {
        background-color: $gray-light !important ;
    }
    [class*='-container']:focus {
        border: none !important;
    }
    [class*='__control'] {
        border: 1px solid #e6ebf1 !important;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.05) !important;
    }

    .form-group-select__indicator,
    .form-group-select--is-multi__indicator {
        padding: 0 10px !important;

        svg {
            height: 20px !important;
            width: 20px !important;

            path {
                fill: $gray-light !important;
            }
        }
    }
    .form-group-select__menu {
        z-index: 2000 !important;
    }
}
.form-group-select_error,
.form-group-select--is-multi_error {
    [class*='-control'] {
        border: 2px solid $danger-fill !important;
    }
}
