@import './variables';

.invoice {
    &__content {
        background: $white;
        min-width: 375px;
        max-width: 800px;
        min-height: 500px;
        margin: auto;
        border-radius: 5px;
        box-shadow: 4px 1px 18px 1px rgba(0, 0, 0, 0.05);
        font-family: gilroy-regular;
    }

    &__description {
        color: $dark;
        padding-top: 30px;

        section {
            margin-bottom: 20px;
        }
        span {
            color: $gray;
            font-size: 1.1em;
        }
        p {
            font-weight: 600;
            color: $dark;
            font-size: 1.1em;
        }
        h2 {
            font-family: gilroy-bold;
        }
    }

    &__footnote {
        color: $gray-light;
        text-align: center;
        font-weight: 600;
        max-width: 400px;
        margin: auto;
        padding: 20px 10px 10px;
    }
}
