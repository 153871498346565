$primary: #17568b;
$secondary: #509fef;
$primary-light: #f6fafe;

// NEUTRAL COLORS
$white: #ffffff;
$light-white: #f7f7fa;
$dark: #071b2b;
$black: #000000;
$gray: #505050;
$border-gray: #ebebf5;
$dark-gray: #39444f;
$dark-light-gray: #0e1b28;
$gray-light: #908e8e;
$gray-form-light: #f6f8fa;
$gray-fill-med: #aaaaaa;
$danger-fill: #c81a2f;
