@import './variables';

.table-responsive {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none;
    }
}
.table__wrapper {
    display: flex;
    justify-content: space-between;
    max-height: 550px;
    width: 100%;

    .thumb-horizontal {
        background-color: red;
        height: 10px !important;
    }

    .table {
        position: relative;
        color: $dark !important;
        margin-bottom: 0 !important;
        width: 100%;
        font-size: 1.2rem;

        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            border-color: rgba(217, 217, 217, 0.25) !important;
        }

        .table__head {
            background: $primary-light;
            font-weight: 600;
            position: sticky;
            top: 0;

            th {
                padding: 1rem 2rem !important;
                border-bottom: 0 !important;
            }

            th,
            td {
                vertical-align: middle !important;
            }
        }

        .table__body {
            img {
                max-height: 25px;
            }
        }

        .table__body,
        .table__head {
            td {
                padding: 1rem 2rem !important;
                border-top: 0 !important;
                vertical-align: middle;
            }
        }
    }

    .table__index {
        width: 30px;
        border: 1px solid rgba(217, 217, 217, 0.25) !important;
        padding: 0 10px;
        font-weight: 600;
        padding-top: 10px;
    }
}

.table_head_no_fill {
    .table {
        .table__head {
            background: transparent !important;
        }
    }
}
