@import './variables';

.subscription-content {
    width: 100%;
    background-color: $white;
    height: 63vh;
    display: flex;

    @media (max-width: 992px) {
        flex-direction: column;
        height: auto;
    }

    .head-1 {
        font-family: 'gilroy-medium';
        font-size: 16px;
        margin: 0;
    }

    .head-2 {
        font-family: 'gilroy-bold';
        font-size: 16px;
        margin: 0;
    }

    p {
        font-size: 14px;
        font-family: 'gilroy-medium';
        margin: 0;
    }

    .left-content {
        display: flex;
        width: 52%;
        height: 100%;
        padding: 33px 37px;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.4rem;
        overflow-y: auto;

        @media (max-width: 992px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            padding: 30px 20px;
        }

        .sub-summary {
            color: #17568b;
        }

        .description {
            width: 100%;

            p {
                font-size: 13px;
                font-family: 'gilroy-medium';
                margin-top: 5px;
            }
        }

        .summary-section {
            width: 100%;
            max-width: 326px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex-shrink: 0;

            & > * {
                padding: 0 10px;
            }

            .title {
                font-family: 'gilroy-medium';
                font-size: 14px;
                color: #82898f;
            }

            .summary-details {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                color: #39444f;

                .amount {
                    font-family: 'gilroy-bold';
                }
                .discount {
                    color: #f18a87;
                    font-family: 'gilroy-bold';
                }
            }

            .billing-section {
                display: flex;
                padding: 0.9rem 1rem;
                flex-direction: column;
                gap: 1rem;
                border: 1px solid #c7d6e3;
                background: #f6f8fa;

                .total {
                    font-family: 'gilroy-bold';
                    color: #17568b;
                }

                .warning {
                    border: 1px solid #f5d495;
                    background: #fdf8ef;
                    font-family: 'gilroy-medium';
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    padding: 6px 8px;

                    img {
                        margin-right: 3px;
                    }
                }
            }
        }

        .create-wallet {
            span {
                color: #509fef;
            }

            button {
                padding: 4px 8px;
                border-radius: 2px;
                border: 1px solid #e2e4e5;
                background: #f7f7fa;
                font-size: 14px;
                font-family: 'gilroy-medium';
            }
        }

        .info {
            .contact {
                font-size: 14px;
                color: #82898f;
                margin-top: 5px;
            }
        }
    }

    .right-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        width: 48%;
        height: 100%;
        padding: 3.4rem 5rem;
        overflow-y: auto;
        background: #f6f8fa;

        @media (max-width: 992px) {
            width: 100%;
            padding: 33px 37px;
        }

        @media (max-width: 767px) {
            padding: 30px 20px;
        }

        .w-48 {
            width: 48%;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .wallet-balance {
            display: flex;
            width: 100%;
            padding: 30px 25px;
            flex-direction: column;
            gap: 20px;
            border-radius: 8px;
            background: #17568b;
            color: $white;

            .available-balance {
                display: flex;
                align-items: flex-start;
                gap: 15px;

                p {
                    color: #c7d6e3;
                    font-size: 13px;
                    line-height: 14px;
                    font-family: 'gilroy-bold';
                    margin-bottom: 4px;
                }
                span {
                    font-size: 24px;
                    letter-spacing: -0.028rem;
                    font-family: 'gilroy-bold';
                }
            }

            button {
                background: #21629c;
                border-radius: 2px;
            }
        }
    }
}
