@import './variables';

.payment-page-container {
    .page-container {
        .contact {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $black;
            font-family: 'gilroy-medium';
        }

        .form-content {
            .details {
                padding: 33px 35px 0 41px;
                height: 60vh;
                overflow-y: scroll;
                .heading {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 26px;
                    color: $dark-gray;
                    font-family: 'gilroy-bold';
                    margin: 0;
                }
                .heading-1 {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: $black;
                    font-family: 'gilroy-medium';
                }
            }
        }
        .form {
            background: $gray-form-light;
            padding: 33px 36px 0 32px;
            height: 60vh;
            overflow-y: scroll;
            h2 {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                font-family: 'gilroy-bold';
                color: $dark-gray;
            }
        }
        .sub-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $dark-light-gray;
            font-family: 'gilroy-medium';
        }
        .heading-1 {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $dark-light-gray;
        }
        .contact-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $dark-light-gray;
            font-family: 'gilroy-medium';
        }
    }

    .payment-page-images {
        gap: 10px;
        width: 100%;
        img {
            width: 267px;
            height: 235px;
        }
    }
}

@media (max-width: 991px) {
    .payment-page-container {
        .page-container {
            .form {
                padding: 20px 13px;
                height: auto;
            }
            .form-content {
                .details {
                    padding: 20px 13px;
                    height: auto;
                }
            }
        }
    }
}
