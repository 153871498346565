@import 'assets/styles/scss/_variables';

// Input Field
.form-group {
    font-size: 1em;
    margin-bottom: 2rem;

    .question {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        border-radius: 50%;
        border: 1px solid $gray-light;
        text-align: center;
        display: inline-block;
        font-size: 10px;
        cursor: pointer;
    }

    input {
        padding: 0.8rem 1rem;
        line-height: inherit;
        font-style: normal;
        color: inherit;
        border-radius: 5px;
        width: 100%;

        &::placeholder {
            font: inherit;
            line-height: inherit;
            color: $gray-fill-med;
        }

        &:read-only {
            cursor: not-allowed;
        }
        // &:disabled {
        // 	background-color: $gray-light;
        // }
    }

    .form-group-label {
        margin-bottom: 0.5rem;
        font-family: gilroy-bold;
        color: $gray-light;
        font-weight: 500;

        &.required {
            &::after {
                content: '*';
                color: $danger-fill;
                margin-left: 3px;
            }
        }
    }

    .input-wrapper {
        border-radius: 5px;
        border: 1px solid #e6ebf1;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.05);

        &.error {
            border: 2px solid $danger-fill;
        }
        &.disabled {
            border: none;
        }
    }

    &.small-form-group {
        input {
            padding: 0.56rem 0.8rem;
            font-size: 1.1rem;
            background: unset !important;
            &::-webkit-calendar-picker-indicator {
                margin-left: 0px !important;
            }
        }
    }

    .password-input-wrapper img {
        cursor: pointer;
    }

    .input-text-error {
        font-size: 0.9em;
        color: $danger-fill;
        font-family: poppins-medium;
    }

    .icon__text {
        padding: 0 10px 0 15px;

        img {
            max-height: 20px;
            min-width: 20px;
        }
    }
}
.custom-check-box {
    margin-bottom: 20px;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    position: relative;
    width: 100%;
    input {
        background-color: white;
        border: 0.5px solid $gray-light;
        border-radius: 2px;
        height: 16px;
        padding: 0;
        min-width: 16px;
        -moz-appearance: none;
        -o-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-sizing: border-box;
        cursor: pointer !important;
        outline: none;
        transition-duration: 0.3s;
        &:checked {
            background-color: $primary;
        }
    }
    input:hover {
        border: 0.5px solid $primary;
    }
    label {
        font-family: 'gilroy-regular';
        font-size: 13px;
        cursor: pointer;
        font-weight: 500;
        margin-left: 10px;
        margin-top: 5px;
        color: $gray-light;
    }
}
