@import 'assets/styles/scss/_variables';

.otp__input {
    input {
        border: 1px solid #e6ebf1 !important;
        width: 40px !important;
        border-radius: 3px !important;
        margin-right: 10px;
        height: 5rem;
        font-family: gilroy-bold;
        font-size: 2rem;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.05);
    }
}
