@import './variables';

@mixin status__pill {
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    min-height: 20px;
    min-width: 30px;
    display: inline;
}

.bg--primary {
    background-color: $primary;
    color: $white;

    svg path {
        fill: $white;
    }
}

.bg--primary--light {
    background-color: $primary-light;
}

.bg--secondary {
    background-color: $secondary;
    color: $white;
}

.text--primary {
    color: $primary;
}

svg {
    &.primary {
        path {
            fill: $primary;
        }
        circle {
            stroke: $primary;
        }
    }
    &.gray {
        path {
            fill: $gray;
        }
    }
}

.overflow-x {
    -ms-overflow-style: none;
    flex-wrap: nowrap;
    scrollbar-width: none;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.overflow-y {
    -ms-overflow-style: none;
    flex-wrap: nowrap;
    scrollbar-width: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.react-tel-input {
    .form-control {
        border: none !important;
        height: 35px !important;
        width: 100%;
        overflow: hidden;

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
    }
    .flag-dropdown {
        background-color: transparent !important;
        border: none !important;
    }
}

.header {
    background: white;
    padding: 26px 35px;
    border: 1px solid #ebebf5;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);

    @media (max-width: 767px) {
        padding: 1.3rem 2rem;
    }
}

.footer {
    font-size: 12px;
    font-family: 'gilroy-medium';
    background: $white;
    padding: 26px 35px;
    border: 1px solid $border-gray;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
    color: var(--text-fill);

    a {
        color: #509fef;
    }

    @media (max-width: 991px) {
        &.flex__between {
            flex-direction: column-reverse;
        }
    }

    @media (max-width: 767px) {
        padding: 3rem 2rem;
    }
}

// PILLS

.pill--success {
    @include status__pill;
    background-color: #0cb95b14;
    border: 1px solid #0cb95b14;
    color: #0cb95b;
}

.pill--warning {
    @include status__pill;
    background-color: #e49b0c14;
    border: 1px solid #e49b0c14;
    color: #e49b0c;
}

.pill--danger {
    @include status__pill;
    background-color: #e31e1814;
    border: 1px solid #e31e1814;
    color: #e31e18;
}
